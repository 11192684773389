import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row, Tag, Tracker } from "../components/components";
import { Breadcrumbs } from "../components/components";
import { useSelector } from "react-redux";
import { RootState } from "store";
const LOAWaiverDeleteConf = () => {
	const { pacode, marketArea, region, salesCode, dealerName } = useSelector((state: RootState) => state.pacode);

	const deletedChart = [
		{
			label: "Select Type",
			active: false,
			current: false,
			completed: true,
		},
		{
			label: "Select Edit Type",
			active: false,
			current: false,
			completed: true,
		},
		{
			label: "Edit Associations & Documents",
			active: false,
			current: false,
			completed: false,
			disabled: true,
		},

		{ label: "Finish", active: true, current: true, completed: false },
	];
	return (
		<Fragment>
			<Col xs={12} className={"fds-m--y-3"}>
				<Breadcrumbs
					className={"fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3"}
					cta={[
						{ href: "/", label: "Waivers", disabled: true },
						{ href: "/", label: "Edit Waiver" },
					]}
				/>

				<div className="loa-tracker-container">
					<Tracker trackers={deletedChart} />
				</div>

				<Row className={"fds-flex--center "}>
					<Col xs={11} lg={10} className={"fds-p--l-0 fds-m--y-2 "}>
						<Row className={"fds-flex--center"}>
							<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-p--y-2 "}>
								Edit Waivers
							</Tag>
						</Row>
						<Row className={"dashboard-title-block"}>
							<Tag tag={"p"} className={"fmc-type--heading6 fds-color--primary fds-p--y-2 "}>
								{dealerName}
							</Tag>
						</Row>
						<Row className={"dashboard-title-block"}>
							<Tag tag={"p"} className={"fmc-type--body2 fds-font--color__gray fds-p--b-3"}>
								PA Code: <span className={"fds-weight--bold"}>{pacode}</span>
								<span className={"fds-m--x-2"}>•</span>
								Sales Code: <span className={"fds-weight--bold"}>{salesCode}</span>
								<span className={"fds-m--x-2"}>•</span>
								Market Area: <span className={"fds-weight--bold"}>{marketArea}</span>
								<span className={"fds-m--x-2"}>•</span>
								Region: <span className={"fds-weight--bold"}>{region}</span>
							</Tag>
						</Row>
					</Col>
				</Row>

				<Row className={"loa-steps-container fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3"}>
					<Col xs={12}>
						<Tag tag={"div"} className={"loa-single-step"}>
							<p className={"fds-weight--bold fds-p--y-1"}>{window.location?.search ? window.location?.search?.split("type=")[1] : ""} deleted from the associated Dealer.</p>

							<Row className={"fds-m--t-3"}>
								<Button
									ariaLabel={"edit additional"}
									onClick={() => {
										window.location.href = "/editloawaivers";
									}}
									buttonClassName={"fds-m--r-2 "}
								>
									Edit Additional
								</Button>

								<Button
									ariaLabel={"dashboard"}
									onClick={() => {
										window.location.href = `/dashboard/${pacode}`;
									}}
									buttonClassName={"fds-m--l-2 LOA-button-outline"}
									dark
								>
									View Dashboard
								</Button>
							</Row>
						</Tag>
					</Col>
				</Row>
			</Col>
		</Fragment>
	);
};

export default LOAWaiverDeleteConf;
