import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Tag, Tracker } from "../components/components";
import { Breadcrumbs } from "../components/components";
import LOAWaiverForm from "../components/components/ModelE/MeDDForm/LOAWaiverForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
const AdminLOAWaiver = () => {
	const [page, setPage] = useState(0);
	const { pacode, marketArea, region, salesCode, dealerName } = useSelector((state: RootState) => state.pacode);

	const step1Chart = [
		{ label: "Select Type", active: true, current: true, completed: false },
		{
			label: "Upload Documents",
			active: false,
			current: false,
			completed: false,
			stepNum: 1,
		},
		{
			label: "Make Associations",
			active: false,
			current: false,
			completed: false,
			stepNum: 2,
		},

		{
			label: "Finish",
			active: false,
			current: false,
			completed: false,
			stepNum: 0,
		},
	];

	const step2Chart = [
		{
			label: "Select Type",
			active: false,
			current: false,
			completed: true,
			stepNum: 1,
		},
		{
			label: "Upload Documents",
			active: true,
			current: true,
			completed: false,
			stepNum: 2,
		},
		{
			label: "Make Associations",
			active: false,
			current: false,
			completed: false,
			stepNum: 2,
		},

		{
			label: "Finish",
			active: false,
			current: false,
			completed: false,
			stepNum: 3,
		},
	];
	const step3Chart = [
		{
			label: "Select Type",
			active: false,
			current: false,
			completed: true,
			stepNum: 0,
		},
		{
			label: "Upload Documents",
			active: false,
			current: false,
			completed: true,
			stepNum: 1,
		},
		{
			label: "Make Associations",
			active: true,
			current: true,
			completed: false,
			stepNum: 2,
		},

		{
			label: "Finish",
			active: false,
			current: false,
			completed: false,
			stepNum: 3,
		},
	];
	const step4Chart = [
		{
			label: "Select Type",
			active: false,
			current: false,
			completed: true,
			stepNum: 0,
		},
		{
			label: "Upload Documents",
			active: false,
			current: false,
			completed: true,
			stepNum: 1,
		},
		{
			label: "Make Associations",
			active: false,
			current: false,
			completed: true,
			stepNum: 2,
		},

		{
			label: "Finish",
			active: true,
			current: true,
			completed: false,
			stepNum: 3,
		},
	];

	let waiverSteps: {
		label: string;
		active: boolean;
		current: boolean;
		completed: boolean;
	}[] = [];

	switch (page) {
		case 0:
			waiverSteps = step1Chart;
			break;
		case 1:
			waiverSteps = step2Chart;
			break;
		case 2:
			waiverSteps = step3Chart;
			break;
		case 3:
			waiverSteps = step3Chart;
			break;
		case 4:
			waiverSteps = step4Chart;
	}
	const trackerClick = (num: number) => {
		setPage(num);
	};
	return (
		<Fragment>
			<Col xs={12} className={"fds-m--y-3"}>
				<Breadcrumbs
					className={"fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3"}
					cta={[
						{ href: "/", label: "Waivers", disabled: true },
						{ href: "/", label: "Upload Documents" },
					]}
				/>

				<div className="loa-tracker-container">
					<Tracker tag={"ul"} trackers={waiverSteps} functionClick={trackerClick} />
				</div>

				<Row className={"fds-flex--center "}>
					<Col xs={11} lg={10} className={"fds-p--l-0 fds-m--y-2 "}>
						<Row className={"fds-flex--center"}>
							<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-p--y-2 "}>
								Upload Waiver
							</Tag>
						</Row>
						<Row className={"dashboard-title-block"}>
							<Tag tag={"p"} className={"fmc-type--heading6 fds-color--primary fds-p--y-2 "}>
								{dealerName}
							</Tag>
						</Row>
						<Row className={"dashboard-title-block"}>
							<Tag tag={"p"} className={"fmc-type--body2 fds-font--color__gray fds-p--b-3"}>
								PA Code: <span className={"fds-weight--bold"}>{pacode}</span>
								<span className={"fds-m--x-2"}>•</span>
								Sales Code: <span className={"fds-weight--bold"}>{salesCode}</span>
								<span className={"fds-m--x-2"}>•</span>
								Market Area: <span className={"fds-weight--bold"}>{marketArea}</span>
								<span className={"fds-m--x-2"}>•</span>
								Region: <span className={"fds-weight--bold"}>{region}</span>
							</Tag>
						</Row>
					</Col>
				</Row>

				<Row className={"loa-steps-container fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3"}>
					<Col xs={12}>
						<LOAWaiverForm page={page} setPage={setPage} />
					</Col>
				</Row>
			</Col>
		</Fragment>
	);
};

export default AdminLOAWaiver;
