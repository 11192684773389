import { Footer, NavBar } from "components/components";
import ScrollToTop from "components/components/Navigation/ScrollToTop";
import { useAuth } from "providers/AuthProvider";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavItem } from "components/components/Navigation/NavBar";

const ProtectedLayout = () => {
	const { isDealer } = useAuth();
	const { t } = useTranslation();
	const navItems: NavItem[] = [
		{
			// label: 'Dashboard',
			label: t("components.navbar.dashboard"),
			aria: t("components.navbar.dashboard"),
			key: "dashboard",
			href: "/",
			id: "sectionDashboard",
		},
		{
			label: t("components.navbar.attestations"),
			aria: t("components.navbar.attestations"),
			key: "attestations",
			href: "/attestations",
			id: "sectionAttestations",
			subMenu: [
				{
					label: "Submit New Attestation",
					aria: "Submit New Attestation",
					key: ":paCode/add-attestation",
					href: "/:paCode/add-attestation",
					id: "sectionNewAttestation",
				},
				{
					label: "Edit Attestation",
					aria: "Edit Attestation",
					key: ":paCode/edit-attestation",
					href: "/:paCode/edit-attestation",
					id: "sectionEditAttestation",
				},
				{
					label: "View Attestation",
					aria: "View Attestation",
					key: ":paCode/attestations",
					href: "/:paCode/attestations",
					id: "sectionEditAttestation",
				},
			],
		},
		{
			label: t("components.navbar.resources"),
			aria: t("components.navbar.resources"),
			key: "resources",
			href: "resources",
			id: "sectionResources",
		},
		{
			label: "Waivers",
			aria: "Waivers",
			key: "loaWaivers",
			href: "loaWaiver",
			id: "sectionLoaWaivers",
			subMenu: [
				{
					label: "Upload Waiver",
					aria: "Upload Waiver",
					key: "loaWaiver",
					href: "loaWaiver",
					id: "sectionUploadLOA",
				},
				{
					label: "Edit Waiver",
					aria: "Edit Waiver",
					key: "editloawaivers",
					href: "editloawaivers",
					id: "sectionEditLOA",
				},
			],
		},
		{
			label: t("components.navbar.contact"),
			aria: t("components.navbar.contact"),
			key: "contact",
			href: "contact",
			id: "ContactForm",
		},
		{
			label: t("components.navbar.reports"),
			aria: t("components.navbar.reports"),
			key: "reports",
			href: "reports",
			id: "sectionReports",
		},
	];

	const siteNavItems = [
		{
			label: "Accessibility",
			key: "accessibility",
			// href: 'https://www.ford.com/help/contact/#accessibility',
			href: t("components.footer.accesibilityLink"),
		},
		{
			label: "Terms & Conditions",
			key: "terms",
			// href: 'https://www.ford.com/help/terms/',
			href: t("components.footer.termsLink"),
		},
		{
			label: "Privacy Notice",
			key: "privacy",
			// href: 'https://www.ford.com/help/privacy/',
			href: t("components.footer.privacyLink"),
		},
		// {
		// 	label: 'Cookie Settings',
		// 	key: 'cookieSettings',
		// 	href: "",
		// },
		{
			label: "Ford.com",
			key: "fordCom",
			// href: 'https://www.ford.com/',
			href: t("components.footer.hreflink"),
		},
	];

	const mNavItems = useMemo(() => {
		return isDealer ? navItems?.filter((item) => item?.id !== "sectionReports") : navItems;
	}, [isDealer, navItems]);

	return (
		<>
			<ScrollToTop />
			<NavBar navItems={mNavItems} hideLinks={false} />
			<main>
				<Outlet />
			</main>

			<Footer background={"none"} siteNavItems={siteNavItems} />
		</>
	);
};

export default ProtectedLayout;
