import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import thunk from "redux-thunk";
import localePreferencesReducer from "./reducers/locale-preferences.slice";
import dealerSearchReducer from "./reducers/dealer-search.slice";
import windowSizeReducer from "./reducers/window-size.slice";
import serviceErrorReducer from "./reducers/service-error.slice";
import userReducer from "./reducers/user.slice";
import pacodeReducer from "./reducers/pacode.slice";
import notificationsReducer from "./reducers/notifications.slice";
import contactUsReducer from "./reducers/contact-us.reducer";
import filesSliceReducer from "./reducers/document.slice";
import serviceErrorMiddleware from "middleware/service-error.middleware";
import gtmMiddleware from "middleware/gtm.middleware";

// API service
import { mecpDealerDashboardApi } from "services/mecp-dashboard";
import { authApi } from "services/auth";
import { adminDashboardApi } from "services/admin-dashboard";
import { reportsApi } from "services/reports";
import { emailNotificationPreferencesApi } from "services/email-notification-preferences";
import { noAccessApi } from "services/no-access";
import { resourceApi } from "services/resource";
import { contactApi } from "services/contact-us";
import { loaDocDetailsApi } from "services/loa-docs-details";
import { currentAssociationApi } from "services/current-association";
import { DeleteAssociationApi } from "services/delete-association";
import { loaWaiverApi } from "services/loa-waiver";
import { loaAssociationApi } from "services/loa-association";
import { mockApi } from "services/mock";
import { dealerDashboardApi } from "services/dashboard";
import { attestationsApi } from "services/attestations";
import { slaLoggingApi } from "services/sla-logging";
import { documentsApi } from "services/document";

export const store = configureStore({
	reducer: {
		serviceError: serviceErrorReducer,
		localePreferences: localePreferencesReducer,
		dealerSearch: dealerSearchReducer,
		windowSize: windowSizeReducer,
		pacode: pacodeReducer,
		contactUs: contactUsReducer,
		notifications: notificationsReducer,
		docId: filesSliceReducer,
		user: userReducer,
		[dealerDashboardApi.reducerPath]: dealerDashboardApi.reducer,
		[mecpDealerDashboardApi.reducerPath]: mecpDealerDashboardApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
		[adminDashboardApi.reducerPath]: adminDashboardApi.reducer,
		[resourceApi.reducerPath]: resourceApi.reducer,
		[noAccessApi.reducerPath]: noAccessApi.reducer,
		[emailNotificationPreferencesApi.reducerPath]: emailNotificationPreferencesApi.reducer,
		[loaAssociationApi.reducerPath]: loaAssociationApi.reducer,
		[loaDocDetailsApi.reducerPath]: loaDocDetailsApi.reducer,
		[loaWaiverApi.reducerPath]: loaWaiverApi.reducer,
		[currentAssociationApi.reducerPath]: currentAssociationApi.reducer,
		[DeleteAssociationApi.reducerPath]: DeleteAssociationApi.reducer,
		[reportsApi.reducerPath]: reportsApi.reducer,
		[contactApi.reducerPath]: contactApi.reducer,
		[mockApi.reducerPath]: mockApi.reducer,
		[attestationsApi.reducerPath]: attestationsApi.reducer,
		[slaLoggingApi.reducerPath]: slaLoggingApi.reducer,
		[documentsApi.reducerPath]: documentsApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(serviceErrorMiddleware)
			.concat(gtmMiddleware)
			.concat(thunk)
			.concat(dealerDashboardApi.middleware)
			.concat(mecpDealerDashboardApi.middleware)
			.concat(emailNotificationPreferencesApi.middleware)
			.concat(noAccessApi.middleware)
			.concat(adminDashboardApi.middleware)
			.concat(authApi.middleware)
			.concat(resourceApi.middleware)
			.concat(loaAssociationApi.middleware)
			.concat(loaDocDetailsApi.middleware)
			.concat(loaWaiverApi.middleware)
			.concat(currentAssociationApi.middleware)
			.concat(DeleteAssociationApi.middleware)
			.concat(reportsApi.middleware)
			.concat(contactApi.middleware)
			.concat(mockApi.middleware)
			.concat(attestationsApi.middleware)
			.concat(slaLoggingApi.middleware)
			.concat(documentsApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
