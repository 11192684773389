import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useGetMasterStatusReportQuery } from "services/reports";
import Breadcrumbs from "components/components/Navigation/Breadcrumbs";
import { useMasterStatusReportDropdownOptions } from "hooks/useMasterStatusReportDropdownOptions";
import { Col, Row } from "components/components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { FormikSelectField } from "components/components/Form/FormikSelectField";
import ReportDownloadButton from "components/components/Form/ReportDownloadButton";
import useDownload from "hooks/useDownload";
import { MecpSpecialistOptions, QualificationOptions } from "constants/MecpConstants";

export const L3CEPNationalSummaryReport: React.FC = () => {
	const { countryCode } = useSelector((state: RootState) => state.localePreferences);
	const {
		monthAndYearOptions,
		certifiedTypesOptions,
		marketAreaOptions,
		getRegionOptions: allRegionOptions, // Use allRegionOptions to get all regions regardless of market
		getZoneOptions: getZoneOptions,
		isError,
		isLoading: isDropdownLoading,
	} = useMasterStatusReportDropdownOptions(countryCode);

	const formik = useFormik({
		initialValues: {
			certificationType: "All",
			qualificationResults: "All",
			marketAreaName: "All",
			regionName: "All",
			zone: "All",
		},
		onSubmit: (values) => {
			setDownloadTriggered(true);
		},
	});

	const [downloadTriggered, setDownloadTriggered] = useState(false);
	const [selectedMarketArea, setSelectedMarketArea] = useState("All");
	const [selectedRegion, setSelectedRegion] = useState("All");

	const { download, isDownloading } = useDownload();

	useEffect(() => {
		if (selectedMarketArea === "All") {
			// Allow all regions if no specific market area is selected
			formik.setFieldValue("regionName", selectedRegion);
		} else {
			// Filter regions based on selected market area and reset selected region
			const filteredRegions = allRegionOptions(selectedMarketArea);
			if (!filteredRegions.includes(selectedRegion)) {
				setSelectedRegion("All"); // Reset selected region if it's not in the new filtered list
				formik.setFieldValue("regionName", "All");
			}
			formik.setFieldValue("marketAreaName", selectedMarketArea);
		}
	}, [selectedMarketArea]);

	useEffect(() => {
		// Update Formik when selectedRegion changes
		formik.setFieldValue("regionName", selectedRegion);
		// Reset zones when changing regions
		if (selectedRegion === "All") {
			formik.setFieldValue("zone", "All");
		}
	}, [selectedRegion]);

	// Dynamically compute zone options based on the selected region
	const zoneOptions = selectedRegion === "All" ? ["All"] : getZoneOptions(selectedRegion);

	const { marketAreaName: marketArea, regionName: region, zone, certificationType: certified, qualificationResults: qualified } = formik.values || {};

	const masterReportUrl = useMemo(() => {
		return `${process.env.REACT_APP_REPORTS_API_HOST}/reports/l3CEPRegionReport`;
	}, []);

	return (
		<>
			<Col xs={12} className="fds-p--y-1">
				<Breadcrumbs
					className="fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3"
					cta={[
						{ href: "/reports", label: "Reports" },
						{ href: "/reports/l3cep-national-summary", label: "L3CEP National Summary Report" },
					]}
				/>

				<Row className="fds-flex--center">
					<Col xs={12} md={8} className="fds-flex--center fds-flex-direction--column fds-p--b-3">
						<h1 className="fmc-type--heading3 fds-color--primary fds-align--left fds-md:fds-align--center fds-p--b-1">L3CEP National Summary Report</h1>
						<p className="fmc-type--body2 fds-font--color__gray fds-flex--center fds-align--left fds-md:fds-align--center fds-p--b-2 fds-p--r-1">Market, Region, Zone and Specialist National Summary roll-up report based on the L3CEP request form submissions.</p>
					</Col>
				</Row>

				<form onSubmit={formik.handleSubmit}>
					<div className="fds-m--y-4 fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-xs:fds-align--center fds-md:fds-align--center">
						<ReportDownloadButton onClick={(e) => download(e, masterReportUrl, `L3CEP_National_Summary_Report_${Date.now()}.xlsx`)} isDropdownFetching={isDropdownLoading} isReportLoading={isDownloading} />
					</div>
				</form>
			</Col>
		</>
	);
};

export default L3CEPNationalSummaryReport;
