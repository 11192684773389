import React, { useCallback, useState } from "react";
import clsx from "clsx";

import { Tag, Grid, Inner, Cell, Button, Row, TopBanner, Col, Container, MobileNavBar, DealerInfo } from "../";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NavItem = ({ href, id, aria, classes, subMenu = [], onNavHandle, onSubMenuToggle, allowToggle = true }: any) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [openSubMenu, setOpenSubMenu] = useState(false);

	const hasSubMenu = subMenu?.length > 0;

	const toggleSubMenu = () => {
		onSubMenuToggle?.(id, openSubMenu);
		if (allowToggle) setOpenSubMenu(!openSubMenu);
	};

	return (
		<>
			<Col
				lg={hasSubMenu ? 2 : 2}
				md={hasSubMenu ? 2 : 2}
				xl={hasSubMenu ? 2 : 2}
				className={hasSubMenu ? "h-100 fds-flex--center fds-flex-direction--column" : "h-100 fds-flex--center fds-flex-direction--column fds-p--x-0"}
				padding={{
					dir: "x",
					amt: hasSubMenu ? "2" : 1,
				}}
			>
				{hasSubMenu ? (
					<Button buttonClassName={clsx(classes)} textButton ariaLabel={aria} className={"navTextResize"} onClick={toggleSubMenu} rightIcon={"navTextResize fds-font--ford-icons__chevron-down fds-icon--offset-right-xs"}>
						{t(`components.navbar.${id}`)}
					</Button>
				) : (
					<Col
						lg={2}
						md={2}
						xl={2}
						className={"h-100 fds-flex--center fds-flex-direction--column fds-p--x-0"}
						padding={{
							dir: "x",
							amt: 1,
						}}
					>
						<Button tag={"a"} href={href} buttonClassName={clsx(classes)} className={"navTextResize"} textButton ariaLabel={aria}>
							{t(`components.navbar.${id}`)}
						</Button>
					</Col>
				)}

				{openSubMenu && (
					<>
						<Tag tag={"div"} className={"backdrop--invisible"} onClick={toggleSubMenu}></Tag>
						<Tag tag={"div"} className={"dropdown-link fds-p--y-1 fds-p--l-2 fds-p--r-4"}>
							<Col className="fds-p--r-3">
								{subMenu?.map((menu: any) => (
									<Row key={menu.id}>
										<Button tag={"a"} href={menu.href} buttonClassName={"fmc-type--body1 fds-color--primary fds-p--b-2"} textButton ariaLabel={`Link to: ${menu.aria}`} onClick={(e: React.MouseEvent<HTMLElement>) => onNavHandle(e, menu.key)}>
											{menu.label}
										</Button>
									</Row>
								))}
							</Col>
						</Tag>
					</>
				)}
			</Col>
		</>
	);
};

export default NavItem;
