import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiResponse } from "types/types";
import { createBaseQuery } from "./overrides/createBaseQuery";

export const slaLoggingApi = createApi({
	reducerPath: "slaLoggingApi",

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_SLA_API_URL}`,
		config: {
			includeSlaApiKey: true,
		},
	}),
	endpoints: (builder) => ({
		postSlaLogging: builder.mutation<ApiResponse<any>, void>({
			query: (payload) => ({ url: "/slalogging", method: "POST", body: payload }),
			async onQueryStarted(props, { dispatch, queryFulfilled }) {
				console.log(props);
			},
		}),
	}),
});

export const { usePostSlaLoggingMutation } = slaLoggingApi;
