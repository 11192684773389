import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedLayout from "layouts/ProtectedLayout";
import ModelEAdminDashboard from "pages/ModelEAdminDashboard";
import Resources from "pages/Resources";
import Contact from "pages/Contact";
import ModelEDashboard from "pages/ModelEDashboard";
import AdminLOAWaiver from "pages/AdminLOAWaiver";
import EditLOAWaiver from "pages/EditLOAWaiver";
import LOAWaiverDeleteConf from "pages/LOAWaiverDeleteConf";
import { MecpReport } from "pages/reports/MecpReport";
import { MasterStatusReport } from "pages/reports/MasterStatusReport";
import Reports from "pages/Reports";
import { PhqReport } from "pages/reports/PhqReport";
import { UserPreferencesReport } from "pages/reports/UserPreferencesReport";
import ErrorPage from "pages/Error";
import ErrorTest from "pages/ErrorTest";
import { ErrorStates } from "types/types";
import Analytics from "pages/admin/analytics";

import Attestation from "pages/attestation";
import ChargingEquipmentReport from "pages/reports/ChargingEquipmentReport";
import NationalRatingsReport from "pages/reports/NationalRatingsReport";
import L3CEPNationalSummaryReport from "pages/reports/L3CEPNationalSummaryReport";

/**
 * A functional component to route admin-related views.
 * @function AdminRouter
 * @param {boolean} props.showComingSoon - A flag to indicate if the coming soon logic should be shown
 * @returns {JSX.Element} Rendered AdminRouter component
 */
const AdminRouter = (): JSX.Element => {
	return (
		<Routes>
			<Route path="/" element={<ProtectedLayout />}>
				<Route index element={<ModelEAdminDashboard />} />
				<Route path="contact" element={<Contact />} />
				<Route path="dashboard/:paCode" element={<ModelEDashboard />} />
				<Route path="resources" element={<Resources />} />
				<Route path="loaWaiver" element={<AdminLOAWaiver />} />
				<Route path="editloawaivers" element={<EditLOAWaiver />} />
				<Route path="loawaiverdelete" element={<LOAWaiverDeleteConf />} />

				<Route path="error" element={<ErrorPage />} />

				<Route path="reports" element={<Reports />} />
				<Route path="reports/mecp" element={<MecpReport />} />
				<Route path="reports/masterstatus" element={<MasterStatusReport />} />
				<Route path="reports/charging-equipment" element={<ChargingEquipmentReport />} />
				<Route path="reports/national-rankings" element={<NationalRatingsReport />} />
				<Route path="reports/l3cep-national-summary" element={<L3CEPNationalSummaryReport />} />
				<Route path="reports/phq" element={<PhqReport />} />
				<Route path="reports/userPreferences" element={<UserPreferencesReport />} />

				<Route path=":paCode/attestations/:attestationType?" element={<Attestation mode="view" />} />
				<Route path=":paCode/add-attestation/:attestationType?" element={<Attestation mode="add" />} />
				<Route path=":paCode/edit-attestation/:attestationType?" element={<Attestation mode="edit" />} />

				<Route path="error-test" element={<ErrorTest />} />

				{/* Admin Prefixed Routes */}
				<Route path="admin/analytics" element={<Analytics />} />

				{/* Catch-all route */}
				<Route path="*" element={<Navigate replace to="/error" state={{ errorState: ErrorStates.AdminOnly }} />} />
			</Route>
		</Routes>
	);
};

export default AdminRouter;
